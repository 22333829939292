<template>

  <div>

    <XModal ref="XModal" :show-overlay="true">

      <template #header>
        Просмотр карточки документа
      </template>

      <template #header_toolbar_right>

        <div v-if="!itemData.decision_time">

          <Button class="p-button-info p-button-outlined mr-3" icon="fas fa-paper-plane"
                  label="Отправить оригинальный контейнер"
                  @click="sendOriginalContainer"/>
          <Button v-if="itemData?.message_dir_converted_path" class="p-button-info p-button-outlined mr-3"
                  icon="fas fa-paper-plane" label="Отправить доструктурированный контейнер"
                  @click="sendConvertedContainer"/>
          <Button
            :class="itemData?.message_dir_converted_path ? 'p-button-warning' : 'p-button-success'"
            :label="itemData?.message_dir_converted_path ? 'Заменить структурированную информацию' : 'Добавить структурированную информацию'"
            class="p-button-outlined" @click='addStructuredPart'/>

        </div>


      </template>

      <template #default>

        <div :style="{minWidth: '30vw', maxWidth: '80vw'}">

          <table class="p-datatable-table" style="width: 100%;">
            <tbody>

            <tr>
              <td>Файлы</td>
              <td>
                <Button
                  :label="'Основной документ ('+ ( itemData?.container_xml_content?.container?.document?.['@localName'] ?? itemData?.container_xml_content?.container?.document?.textFile ) +')'"
                  class="p-button-text p-button-success"
                  icon="pi pi-eye"
                  @click="openMainDocument"/>
                <Button
                  v-for="(attachment, index) in getAttachments"
                  :key="index" :label="attachmentButtonLabel(attachment)" class="p-button-text"
                  @click="attachmentDownload(index)"/>
              </td>
            </tr>

            <tr>
              <td>GUID сообщения</td>
              <td>{{
                  itemData.message_xml_content.communication?.header['@uid'] ?? itemData.message_xml_content?.message?.header?.['@msgUid']
                }}
              </td>
            </tr>

            <tr>
              <td>GUID контейнера</td>
              <td>{{
                  itemData.container_xml_content?.container?.['@uid'] ?? itemData.container_xml_content?.container?.document?.['@docUid']
                }}
              </td>
            </tr>

            <tr>
              <td>Дата документа</td>
              <td>{{ dateToDMY(itemData.doc_date * 1000) }}</td>
            </tr>
            <tr>
              <td>Номер документа</td>
              <td>{{ itemData.doc_number }}</td>
            </tr>
            <tr>
              <td>Аннотация</td>
              <td>{{ itemData.doc_subject }}</td>
            </tr>
            <tr>
              <td>Исполнитель</td>
              <td>{{ itemData.doc_executor }}</td>
            </tr>
            <tr>
              <td>Подписант</td>
              <td>{{ itemData.doc_signer }}</td>
            </tr>

            <tr>
              <td>Отправитель</td>
              <td>{{ itemData.message_xml_content.communication?.header.source.organization }}</td>
            </tr>

            <tr v-if="itemData.decision_time">
              <td>
                <div
                  v-if="itemData.decision_type === 1">Доструктурированный контейнер отправлен получателям
                </div>
                <div
                  v-else-if="itemData.decision_type === 2">Оригинальный контейнер отправлен получателям
                </div>
                <div
                  v-else-if="itemData.decision_type === 3">В СЭД направлена квитанция о невозможности отправить
                  сообщение получателям
                </div>
              </td>
              <td>{{ unixTimeStampToDateTime(itemData.decision_time) }}</td>
            </tr>


            </tbody>
          </table>

          <pre v-if="this.$store.state.debugModeOn" style="font-size: 0.9rem; color: var(--gray-500)">{{
              itemData
            }}</pre>


        </div>


      </template>

      <template #footerLeftOfHide>
        <Button v-if="itemData?.message_dir_converted_path" class="p-button-secondary p-button-outlined mr-3"
                icon="fas fa-download"
                label="Выгрузить оригинальный контейнер" @click="downloadOriginalContainer"/>
        <Button v-if="itemData?.message_dir_converted_path" class="p-button-secondary p-button-outlined mr-3"
                icon="fas fa-download"
                label="Выгрузить доструктурированный контейнер" @click="downloadStructuredContainer"/>
      </template>


    </XModal>

    <PdfModal ref="PdfModal"/>

    <AddStructuredPartModal ref="AddStructuredPartModal"/>


  </div>

</template>

<script>

import {DataFilters, Helper, PdfModal, XModal} from "@xnpmpackages/xcomponents";
import AddStructuredPartModal from "@/components/app/DocumentsConverter/AddStructuredPartModal";
import {XMLParser} from 'fast-xml-parser';

export default {
  name: "DocumentsConverterCard",
  mixins: [Helper, DataFilters],
  emits: ['needUpdateCardInfo'],
  components: {AddStructuredPartModal, PdfModal, XModal},
  data() {
    return {
      itemData: null
    }
  },
  methods: {

    async sendOriginalContainer() {

      try {

        this.$xapi.showBlockModal('Отправка оригинального контейнера...');

        let response = await this.$xapi.post(
          '/doc-converter',
          {
            action: 'sendOriginalContainer',
            doc_id: this.itemData.id,
          },
        );

        await this.$emit('needUpdateCardInfo');

        console.log(response.data);
        this.$xapi.xnotify('Контейнер отправлен')

      } finally {
        this.$xapi.hideBlockModal();
      }

    },

    async sendConvertedContainer() {

      try {

        this.$xapi.showBlockModal('Отправка доструктурированного контейнера...');

        let response = await this.$xapi.post(
          '/doc-converter',
          {
            action: 'sendConvertedContainer',
            doc_id: this.itemData.id,
          },
        );

        await this.$emit('needUpdateCardInfo');

        console.log(response.data);
        this.$xapi.xnotify('Контейнер отправлен')

      } finally {
        this.$xapi.hideBlockModal();
      }

    },

    async downloadStructuredContainer() {

      this.$xapi.showBlockModal('Выгрузка файла...');

      try {
        await Helper.methods.downloadFileFromUrl('/doc-converter/downloadConvertedContainer/' + this.itemData.id);
      } finally {
        this.$xapi.hideBlockModal();
      }

    },

    async downloadOriginalContainer() {

      this.$xapi.showBlockModal('Выгрузка файла...');

      try {
        await Helper.methods.downloadFileFromUrl('/doc-converter/downloadOriginalContainer/' + this.itemData.id);
      } finally {
        this.$xapi.hideBlockModal();
      }

    },

    async addStructuredPart() {

      /*let structuredPartWindowPromise = new Promise(resolve => {
          window.structuredPartResolver = resolve;
      })

      let win = window.open('/#/constructor/selector/structuredPartResolver', '_blank');
      window.focus();

      let xml = await structuredPartWindowPromise;
      console.log(xml);
      win.close();*/

      let links = null;

      if (this.itemData?.container_xml_content?.container?.requisites?.links?.link) {

        links = Array.isArray(this.itemData?.container_xml_content?.container?.requisites?.links?.link)
          ? this.itemData?.container_xml_content?.container?.requisites?.links?.link
          : [this.itemData?.container_xml_content?.container?.requisites?.links?.link];

        links = links.map(item => {
          return {guid: item?.['@uid']};
        })

      }

      if (this.itemData?.container_xml_content?.container?.links?.link) {

        links = Array.isArray(this.itemData?.container_xml_content?.container?.links?.link)
          ? this.itemData?.container_xml_content?.container?.links?.link
          : [this.itemData?.container_xml_content?.container?.links?.link];

        links = links.map(item => {
          return {guid: item?.['@docUid']};
        })


      }

      let portalResult

      if (links) {
        portalResult = await this.$refs.AddStructuredPartModal.select({
          linked_docs: links,
        });
      } else {
        portalResult = await this.$refs.AddStructuredPartModal.select();
      }


      try {

        this.$xapi.showBlockModal('Помещение структурированной информации в контейнер...');

        await this.$xapi.post(
          '/doc-converter',
          {
            action: 'addXmlPartToContainer',
            xml: portalResult?.xml,
            doc_id: this.itemData.id
          },
        );

        this.$xapi.xnotify('Структурированная инфоромация добавлена')

        this.$emit('needUpdateCardInfo');

      } finally {
        this.$xapi.hideBlockModal();
      }


    },

    show($itemData) {

      const parser = new XMLParser({
        ignoreAttributes: false,
        attributeNamePrefix: "@",
        removeNSPrefix: true
      });
      $itemData.message_xml_content = parser.parse($itemData?.message_xml_content);
      $itemData.container_xml_content = parser.parse($itemData?.container_xml_content);

      this.itemData = $itemData;
      this.$refs.XModal.show();

    },

    attachmentButtonLabel($attachment) {
      if ($attachment.description) {
        return $attachment.description + ' (' + ($attachment['@localName'] ?? $attachment['mainFile']) + ')';
      } else {
        return ($attachment['@localName'] ?? $attachment['mainFile']);
      }

    },

    async openMainDocument() {
      this.$refs.PdfModal.renderPdf("/doc-converter/showMainDocInbound/" + this.itemData.id + '/view');
    },
    async attachmentDownload($index) {

      this.$xapi.showBlockModal('Выгрузка файла...');

      try {
        await this.downloadFileFromUrl('/doc-converter/downloadAttachment/' + this.itemData.id + '/' + $index);
      } catch (e) {
        console.error(e);
      } finally {
        this.$xapi.hideBlockModal();
      }

    }

  },
  computed: {

    getAttachments() {
      const att = this.itemData.container_xml_content.container?.attachments?.attachment;

      if (!att)
        return null;

      return Array.isArray(att) ? att : [att]
    }

  }

}
</script>

<style scoped>
.p-datatable-table {
  border-collapse: collapse;
}

.p-datatable-table tbody > tr > td, .p-datatable-table thead > tr > td {
  padding: 5px 20px;
}

.p-datatable-table > tbody > tr:nth-child(even) {
  /*background-color: #0a3622;*/
  background-color: var(--surface-ground);
}
</style>